<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    custom-class="dialog-warpper"
    width="1200px"
  >
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm">
      <el-card>
        <div slot="header">内容设置</div>

        <el-descriptions :column="2" border style="border-bottom: none">
          <el-descriptions-item label="部门">
            <template slot="label">
              <span class="requireSyb">*</span>
              部门
            </template>
            <el-form-item prop="did">
              <el-select
                v-model="dataForm.did"
                placeholder="请选择部门"
                clearable
                @change="didChange"
                :disabled="dataForm.id ? true : false"
              >
                <el-option
                  v-for="item in departmentList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="班组" v-if="dataForm.did">
            <template slot="label">
              <span class="requireSyb">*</span>
              班组
            </template>

            <el-form-item
              prop="tid"
              v-loading="tidSelLoading"
              element-loading-text="数据请求中"
              element-loading-spinner="el-icon-loading"
            >
              <el-select
                v-model="dataForm.tid"
                placeholder="请选择班组"
                clearable
                filterable
                @change="tidChange"
                :disabled="dataForm.id ? true : false"
              >
                <el-option
                  v-for="item in teamList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item
            label="谈话对象"
            v-if="dataForm.did && dataForm.tid"
          >
            <template slot="label">
              <span class="requireSyb">*</span>
              谈话对象
            </template>

            <el-form-item
              prop="intervieweeName"
              v-loading="interviewSelLoading"
              element-loading-text="数据请求中"
              element-loading-spinner="el-icon-loading"
            >
              <el-select
                v-model="dataForm.intervieweeName"
                filterable
                clearable
                placeholder="请选择谈话对象"
                @change="eidChange"
                :disabled="dataForm.id ? true : false"
              >
                <el-option
                  v-for="item in employeeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="谈话人">
            <template slot="label">
              <span class="requireSyb">*</span>
              谈话人
            </template>

            <el-form-item prop="talkerName">
              <el-input
                v-model="dataForm.talkerName"
                placeholder="请选择谈话人"
                clearable
                :disabled="dataForm.id ? true : false"
              ></el-input>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="谈话地点">
            <template slot="label">
              <span class="requireSyb">*</span>
              谈话地点
            </template>

            <el-form-item prop="talkPlace">
              <el-input
                v-model="dataForm.talkPlace"
                placeholder="谈话地点"
                clearable
                :disabled="dataForm.id ? true : false"
              ></el-input>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="谈话时间">
            <template slot="label">
              <span class="requireSyb">*</span>
              谈话时间
            </template>

            <el-form-item prop="talkDate">
              <el-date-picker
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd"
                v-model="dataForm.talkDate"
                placeholder="请选择时间"
                :disabled="dataForm.id ? true : false"
              >
              </el-date-picker>
            </el-form-item>
          </el-descriptions-item>
        </el-descriptions>

        <el-descriptions :column="1" border>
          <el-descriptions-item label="谈话内容">
            <template slot="label">
              <span class="requireSyb">*</span>
              谈话内容
            </template>

            <el-form-item prop="talkContent">
              <editor
                ref="talkContentEditor"
                v-model="dataForm.talkContent"
              ></editor>
            </el-form-item>
          </el-descriptions-item>
        </el-descriptions>
      </el-card>

      <el-card style="margin-top: 10px" v-if="dataForm.id">
        <div slot="header">一、疫情防控</div>

        <el-descriptions :column="2" direction="vertical" border>
          <el-descriptions-item label="今天的身体状况" v-if="dataForm.id">
            <template slot="label">
              <!-- <span class="requireSyb">*</span> -->
              1.今天的身体状况
            </template>

            <el-form-item prop="bodyStatus" v-if="dataForm.id">
              <el-select v-model="dataForm.bodyStatus" disabled placeholder="">
                <el-option
                  v-for="(item, index) in bodyStatusList"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item
            label="今天是否出现发热、干咳、乏力、呼吸困难等症状"
            v-if="dataForm.id"
          >
            <template slot="label">
              <!-- <span class="requireSyb">*</span> -->
              2.今天是否出现发热、干咳、乏力、呼吸困难等症状
            </template>

            <el-form-item prop="isFever" v-if="dataForm.id">
              <el-select v-model="dataForm.isFever" disabled placeholder="">
                <el-option
                  v-for="(item, index) in whetherOpt"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="今天上岗的出行方式" v-if="dataForm.id">
            <template slot="label">
              <!-- <span class="requireSyb">*</span> -->
              3.今天上岗的出行方式
            </template>

            <el-form-item prop="travelMode" v-if="dataForm.id">
              <el-select v-model="dataForm.travelMode" disabled placeholder="">
                <el-option
                  v-for="(item, index) in travelModeList"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-descriptions-item>
        </el-descriptions>
      </el-card>

      <el-card style="margin-top: 10px" v-if="dataForm.id">
        <div slot="header">二、安全运行</div>

        <el-descriptions :column="2" direction="vertical" border>
          <el-descriptions-item
            label="昨天夜里睡眠状况"
            v-if="dataForm.id"
            :labelStyle="{ width: '200px' }"
          >
            <template slot="label">
              <!-- <span class="requireSyb">*</span> -->
              1.昨天夜里睡眠状况
            </template>

            <el-form-item prop="sleepStatus" v-if="dataForm.id">
              <el-select v-model="dataForm.sleepStatus" disabled placeholder="">
                <el-option
                  v-for="(item, index) in sleepStatusList"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item
            label="2.按照公司要求定期参加班组会，落实公司传达工作要求"
            v-if="dataForm.id"
            :labelStyle="{ width: '200px' }"
          >
            <template slot="label">
              <!-- <span class="requireSyb">*</span> -->
              2.按照公司要求定期参加班组会，落实公司传达工作要求
            </template>

            <el-form-item prop="isMeetRequire" v-if="dataForm.id">
              <el-select
                v-model="dataForm.isMeetRequire"
                disabled
                placeholder=""
              >
                <el-option
                  v-for="(item, index) in whetherOpt"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item
            label="3.对公司安全驾驶操作规程是否熟知"
            v-if="dataForm.id"
          >
            <template slot="label">
              <!-- <span class="requireSyb">*</span> -->
              3.对公司安全驾驶操作规程是否熟知
            </template>

            <el-form-item prop="isKnowDriveRule" v-if="dataForm.id">
              <el-select
                v-model="dataForm.isKnowDriveRule"
                disabled
                placeholder=""
              >
                <el-option
                  v-for="(item, index) in whetherOpt"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="4.出车前一夜是否饮酒" v-if="dataForm.id">
            <template slot="label">
              <!-- <span class="requireSyb">*</span> -->
              4.出车前一夜是否饮酒
            </template>

            <el-form-item prop="isDrink" v-if="dataForm.id">
              <el-select v-model="dataForm.isDrink" disabled placeholder="">
                <el-option
                  v-for="(item, index) in whetherOpt"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-descriptions-item>
        </el-descriptions>
      </el-card>

      <el-card style="margin-top: 10px" v-if="dataForm.id">
        <div slot="header">三、思想动态</div>

        <el-descriptions :column="2" direction="vertical" border>
          <el-descriptions-item
            label="1.您对公司整体安全管理工作是否满意"
            v-if="dataForm.id"
            :labelStyle="{ width: '200px' }"
          >
            <template slot="label">
              <!-- <span class="requireSyb">*</span> -->
              1.您对公司整体安全管理工作是否满意
            </template>

            <el-form-item prop="satisfiedWork" v-if="dataForm.id">
              <el-select
                v-model="dataForm.satisfiedWork"
                disabled
                placeholder=""
              >
                <el-option
                  v-for="(item, index) in satisfiedWorkList"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item
            label="您与班组的人员关系如何"
            v-if="dataForm.id"
            :labelStyle="{ width: '200px' }"
          >
            <template slot="label">
              <!-- <span class="requireSyb">*</span> -->
              2.您与班组的人员关系如何
            </template>

            <el-form-item prop="relation" v-if="dataForm.id">
              <el-select v-model="dataForm.relation" disabled placeholder="">
                <el-option
                  v-for="(item, index) in relationList"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item
            label="近期的家庭收入情况如何"
            v-if="dataForm.id"
          >
            <template slot="label">
              <!-- <span class="requireSyb">*</span> -->
              3.近期的家庭收入情况如何
            </template>

            <el-form-item prop="familyIncome" v-if="dataForm.id">
              <el-select
                v-model="dataForm.familyIncome"
                disabled
                placeholder=""
              >
                <el-option
                  v-for="(item, index) in familyIncomeList"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item
            label="近期的家庭成员健康状况、家庭关系有无发生变化"
            v-if="dataForm.id"
          >
            <template slot="label">
              <!-- <span class="requireSyb">*</span> -->
              4.近期的家庭成员健康状况、家庭关系有无发生变化
            </template>

            <el-form-item prop="familyRelationChange" v-if="dataForm.id">
              <el-select
                v-model="dataForm.familyRelationChange"
                disabled
                placeholder=""
              >
                <el-option
                  v-for="(item, index) in whetherOpt"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-descriptions-item>
        </el-descriptions>
      </el-card>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">{{
        dataForm.id ? "关闭" : "取消"
      }}</el-button>
      <el-button type="primary" @click="dataFormSubmit()" v-if="!dataForm.id"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>
  
<script>
import Editor from "@/components/editor.vue";

export default {
  components: {
    Editor,
  },
  data() {
    return {
      visible: false,
      dataForm: {
        id: "",
        talkPlace: "",
        talkDate: "",
        talkerUid: "",
        talkerEid: "",
        talkerName: "",
        intervieweeUid: "",
        intervieweeEid: "",
        intervieweeName: "",
        talkContent: "",
        did: "",
        dname: "",
        tid: "",
        tname: "",
        // bodyStatus: '',
        // isFever: '',
        // travelMode: '',
        // sleepStatus: '',
        // isMeetRequire: '',
        // isKnowDriveRule: '',
        // isDrink: '',
        // satisfiedWork: '',
        // relation: '',
        // familyIncome: '',
        // familyRelationChange: ''
      },
      dataRule: {
        did: [
          { required: true, message: "部门不能为空", trigger: "blur" },
        ],
        tid: [
          { required: true, message: "班组不能为空", trigger: "blur" },
        ],
        talkPlace: [
          { required: true, message: "谈话地点不能为空", trigger: "blur" },
        ],
        talkDate: [
          { required: true, message: "谈话时间不能为空", trigger: "blur" },
        ],
        talkerName: [
          { required: true, message: "谈话人名字不能为空", trigger: "blur" },
        ],
        intervieweeName: [
          { required: true, message: "被谈话人名字不能为空", trigger: "blur" },
        ],
        talkContent: [
          { required: true, message: "谈话内容不能为空", trigger: "blur" },
        ],
        // bodyStatus: [
        //     { required: true, message: '身体状况 0：非常健康 1：良好 2：一般 3：较差不能为空', trigger: 'blur' }
        // ],
        // isFever: [
        //     { required: true, message: '是否发热 0：否 1：是不能为空', trigger: 'blur' }
        // ],
        // travelMode: [
        //     { required: true, message: '上岗出行的方式 0：公共交通 1：私家汽车 2：摩托、电动车 3：其它不能为空', trigger: 'blur' }
        // ],
        // sleepStatus: [
        //     { required: true, message: '睡眠状况 0：非常好 1：良好 2：一般 3：较差不能为空', trigger: 'blur' }
        // ],
        // isMeetRequire: [
        //     { required: true, message: '是否满足要求 0：否 1：是不能为空', trigger: 'blur' }
        // ],
        // isKnowDriveRule: [
        //     { required: true, message: '是否知道驾驶规则 0：否 1：是不能为空', trigger: 'blur' }
        // ],
        // isDrink: [
        //     { required: true, message: '是否饮酒 0：否 1：是不能为空', trigger: 'blur' }
        // ],
        // satisfiedWork: [
        //     { required: true, message: '工作满意情况 0：非常满意 1：比较满意 2：一般 3：不满意不能为空', trigger: 'blur' }
        // ],
        // relation: [
        //     { required: true, message: '与班组人员的关系 0：非常和谐 1：良好 2：一般 3：较差不能为空', trigger: 'blur' }
        // ],
        // familyIncome: [
        //     { required: true, message: '家庭收入情况 0：非常好 1：良好 2：一般 3：困难不能为空', trigger: 'blur' }
        // ],
        // familyRelationChange: [
        //     { required: true, message: '近期家庭关系有没有变化 0：否 1：是不能为空', trigger: 'blur' }
        // ],
        // createDate: [
        //     { required: true, message: '创建时间不能为空', trigger: 'blur' }
        // ],
        // updateDate: [
        //     { required: true, message: '最后修改时间不能为空', trigger: 'blur' }
        // ],
        // isDeleted: [
        //     { required: true, message: '是否已删除不能为空', trigger: 'blur' }
        // ]
      },

      employeeList: [],

      // option---
      // 身体状况opt
      bodyStatusList: [
        { name: "非常健康", value: 0 },
        { name: "良好", value: 1 },
        { name: "一般", value: 2 },
        { name: "较差", value: 3 },
      ],
      // 上岗出行方式opt
      travelModeList: [
        { name: "公共交通", value: 0 },
        { name: "私家汽车", value: 1 },
        { name: "摩托", value: 2 },
        { name: "电动车", value: 3 },
        { name: "其它", value: 4 },
      ],
      // 睡眠状态opt
      sleepStatusList: [
        { name: "非常好", value: 0 },
        { name: "良好", value: 1 },
        { name: "一般", value: 2 },
        { name: "较差", value: 3 },
      ],
      // 是否opt
      whetherOpt: [
        { name: "否", value: 0 },
        { name: "是", value: 1 },
      ],
      // 工作满意情况opt
      satisfiedWorkList: [
        { name: "非常满意", value: 0 },
        { name: "比较满意", value: 1 },
        { name: "一般", value: 2 },
        { name: "不满意", value: 3 },
      ],
      // 与班组人员的关系opt
      relationList: [
        { name: "非常和谐", value: 0 },
        { name: "良好", value: 1 },
        { name: "一般", value: 2 },
        { name: "较差", value: 3 },
      ],
      // 家庭收入情况opt
      familyIncomeList: [
        { name: "非常好", value: 0 },
        { name: "良好", value: 1 },
        { name: "一般", value: 2 },
        { name: "困难", value: 3 },
      ],

      departmentList: [], //  部门列表
      teamList: [], // 班组列表
    };
  },
  methods: {
    dataInit() {
      this.tidSelLoading = false; // 班组选择下拉数据加载状态
      this.interviewSelLoading = false; // 谈话对象选择下拉数据加载状态

      this.dataForm.talkerUid = this.$store.state.userdata.id;
      this.dataForm.talkerEid = this.$store.state.userdata.employee
        ? this.$store.state.userdata.employee.id
        : "";
      this.dataForm.talkerName = this.$store.state.userdata.nickName;
    },
    init(id) {
      this.getDepartmentList();

      this.dataForm.id = id || 0;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();

        this.dataInit();

        // 修改
        if (this.dataForm.id) {
          this.$http.talk
            .info({ id: this.dataForm.id })
            .then((data) => {
              if (data) {
                this.dataForm = data.data;

                this.getTeamList();
                this.getEmployeeList();

                this.$refs.talkContentEditor.destroy();
                this.$refs.talkContentEditor.init(
                  this.dataForm.talkContent || ""
                );
              }
            })
            .then(() => {});
        }
        // 新增
        else {
          this.$refs.talkContentEditor.destroy();
          this.$refs.talkContentEditor.init(this.dataForm.talkContent || "");
        }
      });
    },
    // 获取部门列表
    getDepartmentList() {
      this.$http.department.list({ page: 1, limit: 99999 }).then((res) => {
        if (res.data && res.data.list && res.data.list.length > 0) {
          this.departmentList = res.data.list || [];
        } else {
          this.departmentList = [];
        }
      });
    },
    // 当前选择的部门发生变化
    didChange(e) {
      this.dataForm.tid = "";
      this.dataForm.tname = "";
      this.dataForm.intervieweeUid = "";
      this.dataForm.intervieweeEid = "";
      this.dataForm.intervieweeName = "";

      if (e) {
        // 获取当前选择的部门name字段
        let result = this.departmentList.filter((ele) => {
          return ele.id == e;
        });
        if (result && result.length > 0) {
          this.dataForm.dname = result[0].name;
        }

        this.getTeamList();
      } else {
        this.dataForm.dname = "";
      }
    },
    // 获取班组列表
    getTeamList() {
      this.tidSelLoading = true;

      this.$http.team
        .list({
          did: this.dataForm.did,
          page: 1,
          limit: 99999,
        })
        .then((res) => {
          this.tidSelLoading = false;
          if (res.data) {
            this.teamList = res.data.list || [];
          } else {
            this.teamList = [];
          }
        })
        .catch(() => {
          this.tidSelLoading = false;
        });
    },
    // 当前选择的班组发生变化
    tidChange(e) {
      this.dataForm.intervieweeUid = "";
      this.dataForm.intervieweeEid = "";
      this.dataForm.intervieweeName = "";

      if (e) {
        // 获取当前选择的班组name字段
        let result = this.teamList.filter((ele) => {
          return ele.id == e;
        });
        if (result && result.length > 0) {
          this.dataForm.tname = result[0].name;
        }

        this.getEmployeeList();
      } else {
        this.dataForm.tname = "";
      }
    },

    // 提交表单
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let requestDate = this.dataForm;

          if (this.dataForm.id) {
            // 修改
            this.$http.talk.update(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "修改操作成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          } else {
            // 新增
            requestDate.id = "";
            this.$http.talk.save(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          }
        }
      });
    },
    // 获取员工列表
    getEmployeeList() {
      this.interviewSelLoading = true;
      this.$http.employee
        .list({
          page: 1,
          limit: 99999,
          did: this.dataForm.did,
          tid: this.dataForm.tid,
        })
        .then((res) => {
          this.interviewSelLoading = false;
          if (res.data) {
            this.employeeList = res.data.list || [];
          } else {
            this.employeeList = [];
          }
        })
        .catch(() => {
          this.interviewSelLoading = false;
        });
    },

    // 选择的谈话人发生变化
    eidChange(val) {
      this.employeeList.forEach((ele) => {
        if (ele.name == val) {
          this.dataForm.intervieweeUid = ele.uid;
          this.dataForm.intervieweeEid = ele.id;
        }
      });
    },
  },
};
</script>
  
<style lang="scss" scoped>
:deep() {
  .logn {
    .el-descriptions-item__label {
      width: 300px;
      text-align: center;
      color: #606266;
    }
  }

  .el-descriptions {
    .el-descriptions-item__label {
      width: 100px;
      text-align: left;
      color: #606266;
    }

    .el-descriptions-item__content {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  .el-select {
    width: 100%;
  }

  .el-date-editor {
    width: 100%;
  }

  .el-form-item {
    margin-bottom: 0;
  }
}
</style>